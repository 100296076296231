<template>
  <div :class="name">
    <br />
    <div class="pr-2">
      <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>chevron_right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <div v-if="$router.currentRoute.name === 'transportation-account'" class="pa-2">
<!--      <v-progress-linear intermediate v-show="apiInProgress" height="4"></v-progress-linear>-->
      <v-form v-if="currentAccount && !currentAccountError" @submit.prevent="save()" ref="form">
        <v-layout row wrap>
          <v-flex sm6 pr-2>
            <div class="layout justify-start" v-if="currentAccount.id">
              <v-btn :to="{name: 'transportation-accounts-payouts-schedule', params: { accountId }}" class="mr-2">
                <v-icon small>fal fa-calendar-day</v-icon> &nbsp; Payout scheduler
              </v-btn>
              <v-btn :to="{name: 'transportation-accounts-payouts-payout', params: { payoutId: '0', accountId }}" class="mr-2">
                <v-icon small>fal fa-envelope-open-dollar</v-icon> &nbsp; Make a payout
              </v-btn>
              <v-btn color="mr-2" :to="{name: 'transportation-accounts-vehicles', params: {accountId}}" :disabled="apiInProgress"><v-icon small>fal fa-bus</v-icon> &nbsp; Vehicles</v-btn>
              <v-btn :to="{name: 'transportation-accounts-drivers', params: {accountId}}" :disabled="apiInProgress"><v-icon small>fal fa-address-card</v-icon> &nbsp; Drivers</v-btn>
            </div>
          </v-flex>
          <v-flex sm6>
            <div class="layout justify-end">
              <v-btn color="warning mr-2" flat @click.stop="loadParentView" :disabled="apiInProgress">Cancel</v-btn> <v-btn color="primary" type="submit" v-if="hasUserType('admin') || hasTransportationRole('OWNER')" :disabled="apiInProgress" :loading="apiInProgress">Save</v-btn>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex sm6 pa-2>
            <v-text-field v-model="currentAccount.companyName" pb-2 required :readonly="!hasUserType('admin') && !hasTransportationRole('OWNER')" label="Company name"></v-text-field>
          </v-flex>
          <v-flex sm6 pa-2>
            <v-text-field v-model="currentAccount.contactName" pb-2 required :readonly="!hasUserType('admin') && !hasTransportationRole('OWNER')" label="Contact name"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap v-if="!currentAccount.id">
          <v-flex sm6 pa-2>
            <v-select
              outline
              required
              :items="[{text: 'Phone', value: 'phone'}, {text: 'Email', value: 'email'}]"
              :disabled="!currentAccount.verificationNotice"
              :label="'Verification Type' + (!currentAccount.verificationNotice ? ' (enable \'send verification\' to use)' : '')"
              v-model="currentAccount.verificationType"
            />
          </v-flex>
          <v-flex sm6 pa-2 class="layout justify-start align-baseline">
            <v-tooltip top max-width="400">
              <template v-slot:activator="{ on }">
                <v-btn flat icon><v-icon v-on="on">fal fa-info-circle</v-icon></v-btn>
              </template>
              <span>A default "owner" user is created with a new account. Enable this option to have the user receive a verification email/text message when the account is created, so that they can set their own password. If no verification is sent, the user can always submit a "forgot password" reset request .</span>
            </v-tooltip>
            <v-switch v-model="currentAccount.verificationNotice" label="Send verification to the contact of this account" />
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex sm6 pa-2>
          <v-text-field
              pb-2
              :box="currentAccount.verificationType === 'email'"
              :required="!currentAccount.contactPhone"
              :readonly="!hasUserType('admin') && !hasTransportationRole('OWNER')"
              type="email" v-model="currentAccount.contactEmail"
              :rules="[(v) => (!v || /^[^@]+@[^@]+\.[0-9a-zA-Z]{2,}$/.test(v) || 'Valid email format required')]"
              :label="'Contact email address' + (currentAccount.verificationType === 'phone' ? ' (optional)' : '')">
          </v-text-field>
          </v-flex>
          <v-flex sm6 pa-2>
            <v-text-field
                pb-2
                readonly
                v-if="!hasUserType('admin') && !hasTransportationRole('OWNER')"
                v-model="currentAccount.contactPhone"
                label="Contact phone number">
            </v-text-field>
            <phone-number-field
              pb-2
              v-if="hasUserType('admin') || hasTransportationRole('OWNER')"
              v-model="currentAccount.contactPhone"
              :child-props="{ box: currentAccount.verificationType === 'phone', label: 'Contact phone number' + (currentAccount.verificationType === 'email' ? ' (optional)' : '') }"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex sm6 pa-2>
            <v-select
              outline
              required
              :items="countries"
              :disabled="!hasUserType('admin') && !hasTransportationRole('OWNER')"
              label="Country (location vehicles will be operating in)"
              :value="selectedCountry"
              @change="onFilterByCountry"
            />
          </v-flex>
          <v-flex sm6 pa-2 class="layout justify-start align-baseline">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn flat icon><v-icon v-on="on">fal fa-info-circle</v-icon></v-btn>
              </template>
              <span>Registered tablet devices (Equipment tokens) determine how many buses can have a registered tablet device</span>
            </v-tooltip>
            <v-text-field
                pb-2
                type="number"
                :readonly="!hasUserType('admin')"
                v-model="currentAccount.equipmentTokens"
                :rules="[(v) => (!v || /^\d+$/.test(v) || 'Valid number required')]"
                label="Registered tablet devices allowed">
            </v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap v-if="!currentAccount.id">
          <v-flex sm6 pa-2>
            <div>
              <v-switch v-model="currentAccount.registerMobileMoney" class="mb-2" label="Register mobile money account" />
            </div>
            <div v-show="currentAccount.registerMobileMoney">
              <v-switch v-model="currentAccount.mobileMoneyPhoneIsNotContactPhone" class="mb-2" label="Mobile money phone is different from contact phone" />
              <div v-show="currentAccount.mobileMoneyPhoneIsNotContactPhone">
                <phone-number-field
                  v-model="currentAccount.mobileMoneyPhoneNumber"
                  :child-props="{ label: 'Mobile money phone number' }"
                />
              </div>
            </div>
          </v-flex>
          <v-flex sm6 pa-2>
            <div v-show="currentAccount.registerMobileMoney">
              <v-select
                pb-2
                outline
                clearable
                :items="[{ value: 'mtnghana', text: 'MTN Mobile Money' }, { value: 'atigoghana', text: 'Airtel/Tigo Money' }, { value: 'vodaghana', text: 'Vodafone Cash' }]"
                v-model="currentAccount.moneyAccountTypeId"
                label="Mobile money account"
              />
            </div>
            <div class="mt-2 layout justify-start align-baseline">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn style="margin: 0" flat icon><v-icon v-on="on">fal fa-info-circle</v-icon></v-btn>
                </template>
                <span>Will not display the SmartTransit fee deduction in the SMS/email<br />
                payout report sent to the account owner</span>
              </v-tooltip>
              <v-switch v-model="currentAccount.excludeFeeLabel" class="ml-2" label="Exclude deduction fee in payout report" />
            </div>
          </v-flex>
        </v-layout>
        <v-layout row wrap v-if="isAdmin && isPendingVerification">
          <v-flex sm6 pa-2>
            <v-btn small :disabled="resendingInvitation" :loading="resendingInvitation" @click="resendInvitation()"><v-icon left small>fas fa-envelope</v-icon> Resend Invitation</v-btn>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex sm6 pa-2 v-show="currentAccount.id && currentAccount.dateCreatedLabel">
            Date created: {{currentAccount.dateCreatedLabel}}
          </v-flex>
          <v-flex sm6 pa-2 v-show="currentAccount.dateUpdatedLabel">
            Date updated: {{currentAccount.dateUpdatedLabel}}
          </v-flex>
        </v-layout>
        <div class="layout justify-end">
          <v-btn color="warning mr-2" flat @click.stop="loadParentView" :disabled="apiInProgress">Cancel</v-btn> <v-btn color="primary" type="submit" v-if="hasUserType('admin') || hasTransportationRole('OWNER')" :disabled="apiInProgress" :loading="apiInProgress">Save</v-btn>
        </div>
      </v-form>
      <v-alert
          :value="currentAccountError"
          type="error"
      >
        <p>{{currentAccountError}}</p>
        <p><router-link :to="{ name: 'transportation-account', params: { accountId: accountId } }">Retry</router-link> or <router-link :to="{ name: 'transportation-accounts' }">Return to accounts list</router-link></p>
      </v-alert>
    </div>
    <router-view
        :force-root-view-refresh="forceRootViewRefresh"
        :signed-in-user="signedInUser"
        :selected-account="selectedAccount"
        :set-as-selected-account="setAsSelectedAccount"
        :has-user-type="hasUserType"
        :has-transportation-role="hasTransportationRole">
    </router-view>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
